<template>
  <div class="top-consultation-form">
    <p class="title">転職について相談する</p>
    <p class="description">
      情報収集からでも
      <br />
      お気軽にご相談ください
    </p>
    <nuxt-link to="/registration" class="consultation-form-button">
      <span class="button__text">相談をはじめる</span>
    </nuxt-link>
  </div>
</template>
