<template>
  <div class="top-profession-form">
    <p class="title">各専門領域で転職をはじめる</p>
    <div class="links">
      <div class="links__left">
        <TopProfessionVerticalLink
          to="cpa"
          icon="calculate"
          text="公認会計士"
          sub-icon="license"
        />
      </div>
      <div class="links__right">
        <TopProfessionLink to="cfo" icon="apartment" text="CFO" />
        <TopProfessionLink to="manager" icon="award-star" text="管理部長" />
        <TopProfessionLink to="accounting" icon="savings" text="経理財務" />
        <TopProfessionLink
          to="information_system"
          icon="terminal"
          text="情シス"
        />
        <TopProfessionLink
          to="corporate_planning"
          icon="flowsheet"
          text="経営企画"
        />
        <TopProfessionLink to="human_resource" icon="badge" text="CHRO・人事" />
      </div>
    </div>
  </div>
</template>
