<template>
  <div class="top">
    <div class="main">
      <div class="main__inner">
        <h1 class="main__title">
          ベンチャー・上場企業<span class="main__title-sub">の</span
          ><br />管理部門<span class="main__title-sub">への</span>転職<span
            class="main__title-sub"
            >なら<br v-if="!isPc" /></span
          >WARC エージェント
        </h1>
        <div class="main__title-img">
          <img src="/img/top/title-1.png" alt="" width="229" height="84" />
          <img src="/img/top/title-2.png" alt="" width="249" height="84" />
          <img src="/img/top/title-3.png" alt="" width="270" height="84" />
        </div>
        <div class="main__title-button">
          <TopCustomMainButton />
        </div>
      </div>
    </div>
    <div class="form">
      <TopProfessionForm />
      <TopConsultationForm />
    </div>
    <CompanyLogoList :height="90" />
    <div class="service">
      <h2 class="service__title">
        WARC エージェントは<br
          v-if="!isPc"
        />ベンチャー・IT企業を中心に<br />経営管理部門のプロフェッショナル人材と<br
          v-if="!isPc"
        />企業をつなぐ人材紹介サービスです
      </h2>
      <p class="service__description">
        経営管理部門およびビジネスサイド全般のハイクラス人材を中心にご紹介を行っています。<br />企業と転職者双方に寄り添った人材紹介の本質を追求し、良質な転職体験と成果を両立させることで、<br />当社のファンとなっていただけるようなご支援を目指しています。
      </p>
      <div class="introductions">
        <div v-for="(i, key) in INTRODUCTIONS" :key="key" class="introduction">
          <TopIntroductionBox
            :title="i.title"
            :sub-title="i.subTitle"
            :description="i.description"
          />
        </div>
      </div>
    </div>
    <div class="agents">
      <div class="agents__inner">
        <h2 class="section-title">
          <img
            src="/img/top/icon-career-advisor.svg"
            width="145"
            height="32"
            alt="Inquiry"
            class="section-title__img"
          />
          <p class="agents-title__text">
            キャリアアドバイザー<br v-if="!isPc" />のご紹介
          </p>
        </h2>
        <div class="agents-list">
          <div class="agents-list__box" v-for="a in agents">
            <TopMemberList
              :id="a.id"
              :img="a.mainVisual?.url ?? ''"
              :name="a.name"
              :enName="a.enName"
              :description="a.introduction"
              :job-positions="a.jobPositions"
              :is-pc="isPc"
            />
          </div>
          <div class="agents-list__button">
            <ButtonsTopDefaultButton
              type="link"
              :path="fullUrl('/members')"
              text="すべてのアドバイザーを見る"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="magazine">
      <h2 class="magazine-title">
        <img
          src="/img/top/logo.svg"
          alt="WARC エージェント"
          width="282"
          height="24"
          class="magazine-title__img"
        />
        <img
          src="/img/top/icon-magazine.svg"
          alt="マガジン"
          width="108"
          height="24"
          class="magazine-title__img"
        />
      </h2>
      <p class="magazine-text">
        WARC
        エージェントマガジンは管理部門&IT・ベンチャーの転職最新情報をお届けするメディアです。<br />ご紹介実績のある企業様のインタビューも掲載しております。
      </p>
      <div class="magagin-list">
        <h3 class="magagin-list__title">転職者インタビュー</h3>
        <div class="magagin-list__box author-list__box">
          <template v-for="a in authorArticles">
            <TopMagazineList
              :id="a.id"
              :img="a.mainVisual?.url ?? ''"
              :title="a.title"
            />
          </template>
        </div>
        <div class="magagin-list__button">
          <ButtonsTopDefaultButton
            type="link"
            :path="fullUrl('/articles/categories/support')"
            text="すべての転職者インタビューを見る"
          />
        </div>
      </div>
      <div class="magagin-list" v-if="hiddenCompany">
        <h3 class="magagin-list__title">企業インタビュー</h3>
        <div class="magagin-list__box company-list__box">
          <template v-for="a in companyArticles">
            <TopMagazineList
              :id="a.id"
              :img="a.mainVisual?.url ?? ''"
              :title="a.title"
              colum="single"
            />
          </template>
        </div>
        <div class="magagin-list__button">
          <ButtonsTopDefaultButton text="すべての記事を見る" />
        </div>
      </div>
    </div>
    <!-- コンテンツが充足するまでコメントアウト -->
    <!-- <div class="featured">
      <div class="featured__inner">
        <h2 class="section-title">
          <img
            src="/img/top/icon-featured.svg"
            width="87"
            height="32"
            alt="Featured"
            class="section-title__img"
          />
          <p class="section-title__text">注目の求人特集</p>
        </h2>
        <div class="featured__carousel">
          <nuxt-link
            :to="fullUrl(`/feature_articles/${p.id}`)"
            v-for="p in prArticles"
            class="featured__carousel-box"
          >
            <img
              :src="p.thumbnail.url"
              width="240"
              height="135"
              :alt="p.title"
              class="featured__carousel-img"
            />
            <span class="featured__carousel-title">{{ p.title }}</span>
            <span class="featured__carousel-description">{{
              p.introduction
            }}</span>
          </nuxt-link>
        </div>
      </div>
    </div> -->
    <div class="entry">
      <div class="entry__inner">
        <h2 class="section-title">
          <img
            src="/img/top/icon-entry.svg"
            width="49"
            height="32"
            alt="Entry"
            class="section-title__img"
          />
          <p class="section-title__text">ご利用登録</p>
        </h2>
        <p class="entry-description">
          重視する要素をお選びいただければ、<br
            v-if="!isPc"
          />そちらに合わせた求人をご案内いたします。<br />ご相談からでもお気軽にご登録ください。
        </p>
        <div class="entry-form">
          <TopRegisterForm :is-pc="isPc" />
        </div>
      </div>
    </div>
    <div class="inquiry">
      <h2 class="section-title">
        <img
          src="/img/top/icon-inquiry.svg"
          width="67"
          height="32"
          alt="Inquiry"
          class="section-title__img"
        />
        <p class="section-title__text">お問い合わせ</p>
      </h2>
      <div class="inquiry-buttons">
        <div class="inquiry-buttons__button">
          <ButtonsTopInquiryButton
            type="link"
            path="/contact"
            text="採用をお考えの企業の方はこちら"
          />
        </div>
        <div class="inquiry-buttons__button">
          <ButtonsTopInquiryButton
            type="link"
            :path="fullUrl('/registration')"
            text="転職を考えられている方はこちら"
          />
        </div>
        <div class="inquiry-buttons__button">
          <ButtonsTopInquiryButton
            type="link"
            target="_blank"
            path="https://corp.warc.jp/contact "
            text="その他のお問い合わせはこちら"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ArticleItemViewModel } from '~/core/presenter/Article/ArticleItemViewModel';
import type { PrArticleItemViewModel } from '~/core/presenter/PrArticle/ItemViewModel';
import type { AgentMemberItemViewModel } from '~/core/presenter/AgentMember/AgentMemberItemViewModel';

const INTRODUCTIONS = [
  {
    title: '「両面型サービス」',
    subTitle: '人を扱う仕事だからこそ、人を大切に',
    description:
      '転職者に寄り添ったきめ細かなコミュニケーションや精度の高い求人紹介を通じて、良質な転職体験をご提供します。\nコミュニケーションの中で得られるインサイトを、企業の採用にダイレクトに活用し、同時に企業に厚く寄り添う中で得られた理解と魅力をそのままに転職者に伝えることができます。これにより、なかなかアプローチできない層から企業への応募意思を獲得することを可能にします。',
  },
  {
    title: '深い理解',
    subTitle: '経営管理部門のキャリアに関する',
    description:
      '会計士・戦略コンサル・FASといった様々なバックグラウンドを持つメンバーと連携してご支援しています。1人1人に合った求人をご提案することはもちろん、キャリアパスのご提案、マインドセットのアドバイスも行うことができます。\n一般的な経営管理部門の人材要件だけでなく、ベンチャー企業における経営管理部門の果たすべき役割を理解しているため、それを十二分にご提案に活かすことが可能です。',
  },
  {
    title: '深い知見',
    subTitle: 'ベンチャー業界への',
    description:
      'ベンチャー企業のご支援を通して蓄積した知見を活かし、転職者に対して、今後特に成長が見込まれる企業の目利き、また入社後も見据えたアドバイスが可能です。\n企業に対しては、成長フェーズごとに必要な人材を熟知しているため、高いレベルでの人材要件理解に基づいた最適な人材をご紹介。クライアント企業からは「他サービスと比べ、圧倒的に選考通過率が高い」というご評価をいただいています。',
  },
] as const;

const route = useRoute();
const companyArticles = useState<ArticleItemViewModel[]>('company', () => []);
const authorArticles = useState<ArticleItemViewModel[]>('author', () => []);
const prArticles = useState<PrArticleItemViewModel[]>('pr', () => []);
const agents = useState<AgentMemberItemViewModel[]>(() => []);

definePageMeta({
  layout: 'top',
});

useSeoMeta({
  title:
    'WARCエージェント（ワークエージェント）| 管理部門（公認会計士・経理など）・バックオフィスの転職・就職なら',
  description:
    'WARCエージェント（ワークエージェント）では「人材紹介の『負』の解消を目指す、新しい転職エージェント」をビジョンに、ハイクラス人材紹介事業を展開しています。',
  ogTitle:
    'WARCエージェント（ワークエージェント）| 管理部門（公認会計士・経理など）・バックオフィスの転職・就職なら',
  ogDescription:
    'WARCエージェント（ワークエージェント）では「人材紹介の『負』の解消を目指す、新しい転職エージェント」をビジョンに、ハイクラス人材紹介事業を展開しています。',
  ogType: 'website',
  ogSiteName: 'WARCエージェント（ワークエージェント）',
  ogUrl: fullUrl(route.path),
});

useHead({
  link: [
    {
      rel: 'canonical',
      href: fullUrl(route.path),
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.googleapis.com',
    },
    {
      rel: 'preconnect',
      href: 'https://fonts.gstatic.com',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap',
    },
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Noto+Serif+JP&display=swap',
    },
  ],
});

const isPc = useState(() => false);
const checkWindowWidth = () => {
  isPc.value = screen.width > 767;
};
const hiddenCompany = useState(() => false);

const fetchArticles = async (category: string, limit: number) => {
  const { data } = await useFetch('/api/v1/articles', {
    params: {
      limit,
      orders: '-publishedAt',
      filters: `category[equals]${category}`,
    },
  });

  return data.value;
};

const fetchPrArticles = async () => {
  const { data } = await useFetch('/api/v1/pr_articles', {});

  return data.value;
};

const fetchAgentMembers = async () => {
  const { data }: any = await useFetch('/api/v1/agent_members', {
    params: {
      limit: 6,
      orders: 'order_number',
    },
  });
  return data._rawValue;
};

const [companyRes, authorRes, prRes, agentMemberRes] = await Promise.all([
  fetchArticles('support', 3),
  fetchArticles('support', 6),
  fetchPrArticles(),
  fetchAgentMembers(),
]);

companyArticles.value = companyRes.data;
authorArticles.value = authorRes.data;
prArticles.value = prRes.data;
agents.value = agentMemberRes.data;

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>
